import React from "react";

import clsx from "clsx";
import { graphql } from "gatsby";

import { reduceHeadingSizes } from "~styles/global/global.reduceHeadingSizes.css";
import { legalStyleList } from "~styles/global/global_list_styles.css";

import { usePageData } from "~hooks/use_page_data/use_page_data";

import { formatDate } from "~utils/__deprecated__/date_utils/format_date";

import { CtaCard } from "~components/CtaCard";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { RichTextBlokResolver } from "~components/StoryblokRichText/RichTextBlokResolver";
import { VStack } from "~components/VStack";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import type { CtaCardProps } from "~components/CtaCard";
import type { IconBrandProps } from "~components/IconBrand";
import type { RelationsCardsProps } from "~sections/RelationsCards";
import type { GlobalPageProps } from "~types/global.types";
import type {
  CommonContentPageStoryProps,
  StoryblokBlok,
} from "~types/storyblok.types";

export type DocumentStory = CommonContentPageStoryProps & {
  date?: string;
  datePrefix?: string;
  relatedItems?: Array<StoryblokBlok & RelationsCardsProps>;
  brandIcon?: IconBrandProps["icon"];
  cta: Array<StoryblokBlok & CtaCardProps>;

  // temporarily not mandatory for backwards compatibility with metaTitle
  title?: string;
};
export type TemplateDocumentProps = GlobalPageProps<DocumentStory>;

export { Head } from "~components/Head";

// const CARD_SUB_COMPONENTS: Array<CardSubComponent> = ["icon", "title"];

export default function TemplateDocument(props: TemplateDocumentProps) {
  const { story, pageContext } = usePageData<TemplateDocumentProps>(props);
  const {
    pageMetadata,
    title,
    bodyRichText,
    cta: ctaBlokArray,
    date,
    datePrefix,
  } = story || {};

  // ensuring backwards compatibility for pages without new title field valued
  const { title: metaTitle } = pageMetadata || {};

  const { breadcrumbs } = pageContext || {};

  return (
    <LayoutPage {...props}>
      {/**
       * -------------------------
       * Page hero
       * -------------------------
       */}

      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={title || metaTitle}
        date={formatDate(date, true)}
        datePrefix={datePrefix}
      />

      <LayoutSidebar.Wrapper
        sidebarPositionDesktop="right"
        sidebarPositionMobile="bottom"
      >
        <LayoutSidebar.Primary>
          <StoryblokRichText
            className={clsx(reduceHeadingSizes, legalStyleList)}
            defaultBlokResolver={RichTextBlokResolver}
            text={bodyRichText}
          />
        </LayoutSidebar.Primary>

        <LayoutSidebar.Secondary isSidebarSticky>
          <VStack gap="spacing6">
            {Array.isArray(ctaBlokArray) &&
              ctaBlokArray.map((ctaBlok) => {
                return (
                  <StoryblokEditable {...ctaBlok}>
                    <CtaCard {...ctaBlok} />
                  </StoryblokEditable>
                );
              })}
          </VStack>
        </LayoutSidebar.Secondary>
      </LayoutSidebar.Wrapper>
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateDocumentQuery(
    $id: String
    $languageRegex: String
    $lang: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
  ) {
    ...pageContentStoryblokEntry
    ...global
  }
`;
